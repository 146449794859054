<template>
	<div id="main-wrapper" class="inner-pages forex-page indices-page cryp-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li> <router-link to="/forex">Forex</router-link> </li>
						<li> <router-link to="/indices">Indices</router-link> </li>
						<li> <router-link to="/commodities">Commodities</router-link> </li>
						<li> <router-link to="/stocks">Stocks</router-link> </li>
						<li class="active"> <router-link to="/crypto">Cryptocurrencies</router-link> </li>
					</ul>
				</div>
			</div>
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>CRYPTOCURRENCIES</h2>
				<p>Trade the most powerful cryptocurrencies on our trading platforms</p>
			</div>
		</div>
		<div class="section strip-details">
			<div class="wrapper2">
				<div class="strip-info wow fadeIn">
					<h3>min. <span>0.01</span> Trade Size</h3>
				</div>
				<div class="strip-info wow fadeIn" data-wow-delay="0.3s">
					<h3>min. execution <span>10ms</span></h3>
				</div>
				<div class="strip-info wow fadeIn" data-wow-delay="0.6s">
					<h3> <span>6</span> Pairs</h3>
				</div>
				<div class="strip-info wow fadeIn" data-wow-delay="0.9s">
					<h3>  Up to <span>2x</span> Leverage</h3>
				</div>
			</div>
		</div>
		<div class="section forex-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn">
					<h3>Cryptocurrencies Contract <strong>Specifications</strong></h3>
					<p>More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at <a href="mailto:support@aaafx.com">support@aaafx.com</a></p>
				</div>
				<div class="table-holder home-table wow fadeIn">
					<div class="search-holder">
						<input type="text" name="" value="" placeholder="Search Instrument">
					</div>
					<table>
						<tr>
							<th>Instrument</th>
							<!-- <th>Leverage</th> -->
							<th>Bid</th>
							<th>Ask</th>
							<th class="">Spread</th>
							<th class="">Contract Size</th>
							<th class="">Min. Trade Size</th>
							<th class="">Tick Value</th>
							<th>Trading Hours</th>
						</tr>
						<tr v-for="(spreads,key,index) in get_currencies" :key="key" :index="index" v-show="index < page*limit && index >= (page-1)*limit">
							<td><!--  <img src="assets/images/bitcoin-ico.webp" alt="">  -->{{key}}</td>
							<!-- <td><span class="lev">1-30X</span></td> -->
							<td><span :class="[{'t-green':spreads.buy_increase_staus == 1 ,'t-red':spreads.buy_increase_staus == 0 }]">{{spreads.B}}</span></td>
							<td><span :class="[{'t-green':spreads.sell_increase_staus == 1 ,'t-red':spreads.sell_increase_staus == 0 }]">{{spreads.A}}</span></td>
							<td class="">{{spreads.SPREAD || '-'}}</td>
							<td class="">{{spreads.other.Contract_Size}}</td>
							<td class="">{{spreads.other.Min_Trade_Size}}</td>
							<td class="">{{spreads.other.Tick_Value}}</td>
							<td class="">7:00 am - 12:00pm</td>
						</tr>
					</table>
					<div class="clearfix links">
							<!-- <a href="#" class="t-right see-link f-right"> Next</a> -->
							<pagination :totalRecords="Object.keys(this.get_currencies).length" :limit="limit" :pageNo="page"/>
					</div>
				</div>

				<div class="section-small">
					<div class="accordion-holder t-left">
						<h3 v-on:click="Cryptocurrency ^= true" :class="Cryptocurrency?'active':''">What is Cryptocurrency Trading?</h3>
						<div class="content" v-show="Cryptocurrency">
							<p>Cryptocurrencies are essentially digital assets secured by cryptography  that move through a decentralised electronic network. These assets are not backed by or not convertible into any commodity. They work via a public ledger, based on Blockchain technology. Bitcoin, released in 2009, is the first decentralized cryptocurrency. Since then, many other cryptocurrencies have been created. These coins have really been a game changer in the financial markets.</p>
						</div>
						<h3 v-on:click="work ^= true" :class="work?'active':''">How does it work?</h3>
						<div class="content" v-show="work">
							<p>The traditional fiat currencies are generally influenced by the economic and political events happening across the globe. However, since crypto currencies are not related to any particular country and are generally decentralised, their prices tend to move independently. They are a high grade volatile class of assets, which makes it imperative for traders to weigh the risk before trading in them. We offer CFDs of cryptos, which means that you can simply trade in cryptos without having to own them. The profits and losses owing to price differential are simply adjusted in your account.These instruments appear as a pair, quoted against USD. For example, BTCUSD. The price of this pair will imply how many USDs are required against 1 BTC.</p>
						</div>
					</div>
				</div>

				<div class="section account-types">
					<div class="title-holder t-left wow fadeIn">
						<h3>Account <strong>Types</strong></h3>
					</div>
					<div class="table-holder home-table acct-type wow fadeIn">
						<table>
							<tr>
								<th>&nbsp;</th>
								<th>ECN</th>
								<th>ECN <span>plus</span> </th>
								<th>ECN <span>zulu</span> </th>
							</tr>
							<tr>
								<td>Deposit (min.)  </td>
								<td>$10</td>
								<td>$10,000</td>
								<td>$300</td>
							</tr>
							<tr>
								<td>Commission (per $100k)</td>
								<td>0.001*Price</td>
								<td>0.0008*Price</td>
								<td>0.002*Price</td>
							</tr>
							<tr>
                <td>Spread (min.)</td>
                <td>0.0</td>
                <td>0.0</td>
                <td>0.0</td>
              </tr>

						</table>
						<div class="clearfix links">
								<a href="account-types" target="_blank" class="t-right see-link f-right"> Compare Account Types</a>
						</div>
					</div>
				</div>

				<div class="section trading-plat">
					<div class="info-holder wow fadeInLeft">
						<div class="title-holder">
							<h3>Our Online  <strong>Trading <br>Platforms</strong> </h3>
						</div>
						<div class="link-holder col">
							<a href="#"> <img src="assets/images/plat-ico1.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Desktop</a>
						</div>
						<div class="link-holder col">
							<a href="#"> <img src="assets/images/plat-ico2.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Web Trader</a>
						</div>
						<div class="link-holder col">
							<a href="#"> <img src="assets/images/plat-ico3.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> Android</a>
						</div>
						<div class="link-holder col">
							<a href="#"> <img src="assets/images/plat-ico4.webp" alt="AAAFx" title="AAAFx" width="69" height="55" class="fluid-img" /> iOS</a>
						</div>
						<div class="btn-holder">
							<router-link to="/compare-platform" class="btn btn-bluey">LEARN MORE</router-link>
						</div>
					</div>
					<div class="links-holder wow fadeInRight">
						<div class="link-holder col">
							<img src="assets/images/plat1.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
						</div>
						<div class="link-holder col">
							<img src="assets/images/plat2.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
						</div>
						<div class="link-holder col">
							<img src="assets/images/plat3.webp" alt="AAAFx" title="AAAFx" width="237" height="100" class="fluid-img" />
						</div>
						<!-- <div class="link-holder col">
							<img src="assets/images/plat4.webp" alt="">
						</div> -->
					</div>
				</div>

				<div class="section nega-sec">
					<div class="content-holder wow fadeInLeft">
						<div class="title-holder">
							<h3>Negative Balance <strong>Protection</strong> </h3>
							<p class="sub">Reliable protection of your funds on AAAFx Accounts</p>
							<br>
							<p>We always endeavor to work towards the best interest of our clients. While trading in volatile times, if your account goes into negative, we have got your back! We will reset your negative balance to zero, so that you do not bear any extra loss. You can deposit and start trading again. Hence, you cannot lose more than what you deposit.</p>
							<div class="btn-holder">
								<a :href="siteurl2" target = "_blank" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
								<a :href="siteurl2" target = "_blank" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
							</div>
						</div>
					</div>
					<div class="img-holder t-center wow fadeInRight">
						<img src="assets/images/nega-img.webp" alt="AAAFx" title="AAAFx" width="579" height="433" class="fluid-img" />
					</div>
				</div>

			</div>
		</div>


	</div>
</template>
<script>
import { Const } from '../plugins/vue-provider-cache/const.mod'
import pagination from '@/components/shared/pagination.vue'
export default {
  name: "forex",
  data(){
		return{
			page:1,
			limit:8,
			Cryptocurrency:true,
			work:false,
			siteurl : process.env.VUE_APP_CLIENT_PORTAL_URL,
      siteurl2 : process.env.VUE_APP_CLIENT_REGISTER_URL, 
		}
  },
  components:{
		pagination: pagination
  },
  computed:{
    get_currencies(){
      try {
        // just 'feel' the pulse here so we get a reactive response
        // as the cache is not reactive  
        this.$store.getters[Const.GET_HEARTBEATS]['priceFeed']
        return this.$store.getters[Const.GET_PROVIDERS]['priceFeed']
        .iCache.getCryptosAll()
      } catch (ex) {
        // console.log('OO-one [ex]', ex)
        return []
      }
    }
  },
};
</script>